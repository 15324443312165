import Container from 'react-bootstrap/Container'
import GoogleAdSense from './GoogleAdSense'
import { useLocation } from 'react-router-dom'

const Footer = () => {
  const location = useLocation()
  return (
    <div className="bg-dark footer">
      <Container>
        The content of this site is intended for personal purposes only and is
        not guaranteed to be accurate.{' '}
        <a href="https://www.paypal.com/donate/?hosted_button_id=MNW539C3V756W">
          Donate
        </a>{' '}
        to help keep the site running.
      </Container>
      <GoogleAdSense
        slot="4891282646"
        className="responsive_ads"
        key={location.pathname}
      />
    </div>
  )
}

export default Footer
