import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import { Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { BagDashFill, BagPlus, ZoomIn } from 'react-bootstrap-icons'
import {
  getTrackerLink,
  isCurrentPrice,
  retailerList,
  toPriceString
} from '../utils'
import { addToBasket, removeFromBasket } from '../reducers/basketReducer'
import { useState } from 'react'
import RetailerLink from './RetailerLink'
import productService from '../services/product'
import LowPriceBadge from './LowPriceBadge'
import pcService from '../services/pc'

const ProductCard = ({
  product,
  regPrice,
  price,
  sale,
  image,
  basketPage = false
}) => {
  const basket = useSelector(({ basket }) => basket)
  const store = useSelector(({ store }) => store.id)
  const user = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  const basketStore = (basket && store === basket.storeID) || basketPage //Are we searching the same store as the basket's store? False if there is no basket/user isn't logged in
  const [showModal, setShowModal] = useState(false)

  const addProduct = async (event, code) => {
    event.preventDefault()
    await dispatch(addToBasket(code))
  }

  const removeProduct = async (event, code) => {
    event.preventDefault()
    await dispatch(removeFromBasket(code))
  }

  const isCurrent = isCurrentPrice(product.prices[product?.prices?.length - 1])
    ? ''
    : ' old-price'

  const displayPrice = sale ? (
    regPrice ? (
      <span className={`sale${isCurrent}`}>
        {toPriceString(price)} <s>({toPriceString(regPrice)})</s>
      </span>
    ) : (
      <span className={`sale${isCurrent}`}>{toPriceString(price)}</span>
    )
  ) : (
    <span className={`${isCurrent}`}>{toPriceString(price)}</span>
  )

  const productPrice = product.prices.length
    ? product.prices[product.prices.length - 1]
    : product.prices

  const unitSize = productPrice.size
    ? /[a-z]/i.test(productPrice.size)
      ? productPrice.size
      : `${productPrice.size} ${productPrice?.unit || ''}`
    : null

  return (
    <>
      <Col>
        <Card border="dark">
          <Card.Body>
            <div style={{ minHeight: '6em' }}>
              <LowPriceBadge
                prices={product.prices}
                isCurrent={basketPage && isCurrent === ''}
              />
              <Card.Title>{product.name}</Card.Title>
              <Card.Subtitle>{product.brand}</Card.Subtitle>
            </div>
            {retailerList[product.retailer].service !== pcService ? (
              <Card.Img
                src={image ? image : null}
                alt={`${product.name} - ${product.brand}`}
              />
            ) : (
              ''
            )}
            <div className="cardPrices">{displayPrice}</div>
            <div className="unitSize">{unitSize}</div>
            <div className="unitPrice">
              {productService.getUnitPrice(productPrice)}
            </div>
            <div className="cardLinks">
              <Card.Link
                to={getTrackerLink(product)}
                as={Link}
                title="View Item"
              >
                <ZoomIn />
              </Card.Link>
              {basketStore && user && basket ? (
                basket.products.some(
                  (p) => `${p.code}` === `${product.code}`
                ) ? (
                  <Card.Link
                    href="#"
                    onClick={(event) => removeProduct(event, product.code)}
                    title="Remove from Basket"
                  >
                    <BagDashFill />
                  </Card.Link>
                ) : (
                  <Card.Link
                    href="#"
                    onClick={(event) => addProduct(event, product.code)}
                    title="Add to Basket"
                  >
                    <BagPlus />
                  </Card.Link>
                )
              ) : !user ? (
                <Card.Link
                  href="#"
                  onClick={(event) => {
                    event.preventDefault()
                    setShowModal(true)
                  }}
                >
                  <BagPlus />
                </Card.Link>
              ) : (
                ''
              )}
              <RetailerLink product={product} />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body closeButton>
          Please login or register to add products to a basket.
        </Modal.Body>
        <Modal.Footer>
          <Link to="/login">
            <Button>Login/Sign Up</Button>
          </Link>
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProductCard
