import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setError, setNotification } from '../reducers/notificationReducer'
import loginService from '../services/login'

const EmailVerification = () => {
  const dispatch = useDispatch()
  const token = useParams().token
  const username = useParams().username
  const user = useSelector(({ user }) => user)

  useEffect(() => {
    const verifyEmail = async () => {
      const request = await loginService.verifyEmail({
        username: username,
        token: token
      })
      if (request?.status === 200) {
        dispatch(setNotification(`Email successfully verified!`))
      } else {
        dispatch(setError(request.data))
      }
    }

    const requestVerification = async (user) => {
      const request = await loginService.requestEmailVerification(user.username)
      if (request?.status === 200) {
        dispatch(
          setNotification(
            `Email sent to ${user.email}. Please check your inbox and click the link to verify.`
          )
        )
      } else {
        dispatch(setError(request.data))
      }
    }

    if (token && username) {
      verifyEmail()
    } else if (user && user.email && !user.verified) {
      requestVerification(user)
    }
  }, [dispatch, token, username, user])

  return <div></div>
}

export default EmailVerification
