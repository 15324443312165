import { Row, Col } from 'react-bootstrap'
import { Chart } from 'react-chartjs-2'
import { lineColors, toPriceString } from '../utils'
import { enUS } from 'date-fns/locale'
import { useEffect, useState } from 'react'
import productService from '../services/product'
import locationService from '../services/location'
import storesService from '../services/stores'

const PriceCharts = ({ allProducts, store, product, unitPrice }) => {
  const [data, setData] = useState()
  const [unitData, setUnitData] = useState()

  const updateData = async () => {
    const sortStores = async () => {
      const allStores = await storesService.getStores(product.retailer)
      const thisStore = allStores.find((s) => s.id === store)
      const regionStores = allStores.filter(
        //find only store in the same region
        (s) => s.region === thisStore.region
      )
      return locationService.sortByLocation(
        regionStores,
        thisStore.latt,
        thisStore.long
      )
    }
    const sortedStores = await sortStores()
    const sortedData = sortedStores
      .flatMap((s) => {
        const thisProduct = allProducts.find((p) => p.storeID === s.id)
        return thisProduct ? thisProduct : []
      }) //connect data from the db with the list of stores
      .slice(0, 5) //only show five stores

    const data = sortedData.map((d, i) => {
      return {
        label: sortedStores.find((s) => s.id === d.storeID).name,
        borderColor: lineColors[i].border,
        pointBackgroundColor: lineColors[i].border,
        pointHoverBackgroundColor: lineColors[i].bg,
        pointBorderWidth: 2,
        pointHoverBorderWidth: 1,
        data: d.prices.flatMap((price, i) => {
          if (
            d.prices[i - 1] &&
            d.prices[i - 1]?.date.substr(0, 10) === price.date.substr(0, 10)
          ) {
            return [] //remove duplicates by returning [] if same date as previous entry
          }
          return {
            x: price.date.substr(0, 10),
            y: price.price
          }
        })
      }
    })

    const unitData = sortedData.map((d, i) => {
      return {
        label: sortedStores.find((s) => s.id === d.storeID).name,
        borderColor: lineColors[i].border,
        pointBackgroundColor: lineColors[i].border,
        pointHoverBackgroundColor: lineColors[i].bg,
        pointBorderWidth: 2,
        pointHoverBorderWidth: 1,
        data: d.prices.flatMap((price, i) => {
          if (
            d.prices[i - 1] &&
            d.prices[i - 1]?.date.substr(0, 10) === price.date.substr(0, 10)
          ) {
            return [] //remove duplicates by returning [] if same date as previous entry
          }
          return {
            x: price.date.substr(0, 10),
            y: productService.getUnitPrice(price, false)
          }
        })
      }
    })
    setData(data)
    setUnitData(unitData)
  }

  useEffect(() => {
    if (allProducts && store) {
      updateData(allProducts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts, store])

  if (!data || !unitData) {
    return <div></div>
  }

  return (
    <Row xs={1} sm={1} md={2} lg={2} xl={2} className="g-5">
      <Col>
        <h4>Price History</h4>
        <Chart
          type="line"
          options={{
            stepped: true,
            aspectRatio: 1.75,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      toPriceString(context.parsed.y) +
                      ' - ' +
                      context.dataset.label
                    )
                  }
                }
              }
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'week',
                  tooltipFormat: 'MMM dd, yyyy'
                },
                adapters: {
                  date: {
                    locale: enUS
                  }
                }
              },
              y: {
                ticks: {
                  // Format the ticks as a price $0.00
                  callback: function (value, index, ticks) {
                    return toPriceString(value)
                  }
                }
              }
            }
          }}
          data={{
            datasets: data
          }}
        />
      </Col>
      <Col>
        <h4>Unit Price History</h4>
        <Chart
          type="line"
          options={{
            stepped: true,
            aspectRatio: 1.75,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      toPriceString(context.parsed.y) +
                      ' /' +
                      unitPrice.split('/')[1] +
                      ' - ' +
                      context.dataset.label
                    )
                  }
                }
              }
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'week',
                  tooltipFormat: 'MMM dd, yyyy'
                },
                adapters: {
                  date: {
                    locale: enUS
                  }
                }
              },
              y: {
                ticks: {
                  // Format the ticks as a price $0.00
                  callback: function (value, index, ticks) {
                    return toPriceString(value)
                  }
                }
              }
            }
          }}
          data={{
            datasets: unitData
          }}
        />
      </Col>
    </Row>
  )
}

export default PriceCharts
