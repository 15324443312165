import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setError } from '../reducers/notificationReducer'
import { logoutUser } from '../reducers/userReducer'

const AuthVerification = ({ history }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector(({ user }) => user)
  const notification = useSelector(({ notification }) => notification)
  const dispatch = useDispatch()

  useEffect(() => {
    const checkVerification = () => {
      if (user) {
        const expiryTime = user.expiry
        if (Date.now() >= expiryTime) {
          dispatch(logoutUser())
          dispatch(setError('Your session has expired, please login again'))
          navigate(`/login?redirect=${location.pathname}`)
        }
      }
    }
    checkVerification()
  }, [location, user, notification, navigate, dispatch])
  return <></>
}

export default AuthVerification
