import { useState, useEffect } from 'react'
import Stack from 'react-bootstrap/Stack'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import StoreSelect from './StoreSelect'
import { useDispatch, useSelector } from 'react-redux'
import { setError } from '../reducers/notificationReducer'
import { useNavigate, useParams } from 'react-router-dom'
import { retailerList } from '../utils'

const SearchForm = ({ retailer }) => {
  const storeFromRedux = useSelector(({ store }) => store.id)
  const store = useParams().store || storeFromRedux
  const [search, setSearch] = useState(useParams().term || '')
  const [formStore, setStore] = useState('')
  const [formRetailer, setFormRetailer] = useState(retailer || 'superstore')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSearch = async (event) => {
    event.preventDefault()

    if (formStore === '') {
      dispatch(setError('Please select a store'))
    } else {
      if (search === '') {
        dispatch(setError('Please enter a search term'))
      } else {
        navigate(
          `/search${retailerList[formRetailer]?.trackerLink}${formStore}/${search}`
        )
      }
    }
  }

  useEffect(() => {
    if (store) {
      setStore(store)
    }
  }, [store])

  useEffect(() => {
    if (retailer) {
      setFormRetailer(retailer)
    }
  }, [retailer])

  return (
    <div style={{ marginBottom: '2em' }}>
      <Col lg="8">
        <h3>Find a Product</h3>
        <form onSubmit={handleSearch}>
          <Form.Control
            value={search}
            placeholder="Search for..."
            type="text"
            onChange={({ target }) => setSearch(target.value)}
            className="d-block d-sm-none" // Only displayed on xs screens
          />
          <Stack direction="horizontal" gap={2}>
            <Form.Control
              value={search}
              placeholder="Search for..."
              type="text"
              onChange={({ target }) => setSearch(target.value)}
              className="d-none d-sm-block" // Not displayed on xs screens
            />
            <StoreSelect
              store={formStore}
              setStore={setStore}
              retailer={formRetailer}
              setRetailer={setFormRetailer}
            />
            <Button
              type="submit"
              className="d-none d-sm-block"
              //Not displayed on xs screens
            >
              Search
            </Button>
          </Stack>
          <Button
            type="submit"
            className="d-block d-sm-none"
            //Only displayed on xs screens
          >
            Search
          </Button>
        </form>
      </Col>
      <div className="sale">
        Note: We are currently unable to search or retreive new price data from
        Walmart.
      </div>
    </div>
  )
}

export default SearchForm
