import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setError, setNotification } from '../reducers/notificationReducer'
import loginService from '../services/login'

const PasswordReset = () => {
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const token = useParams().token
  const username = useParams().username

  const handleReset = async (event) => {
    event.preventDefault()
    const request = await loginService.resetPassword({
      username: username,
      token: token,
      password: password
    })
    if (request?.status === 200) {
      dispatch(
        setNotification(
          `Password reset. Please return to the login page and login using your new password.`
        )
      )
    } else {
      dispatch(setError(request.data))
    }
  }

  return (
    <Form onSubmit={handleReset}>
      <h5>Please enter a new password</h5>
      <br />
      <div>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          name="Password"
          id="password"
          onChange={({ target }) => setPassword(target.value)}
        />
      </div>
      <br />
      <Button type="submit" id="login-button">
        Set Password
      </Button>
      <p> </p>
    </Form>
  )
}

export default PasswordReset
