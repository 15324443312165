import axios from 'axios'
const baseUrl = '/api/login'

const login = async (credentials) => {
  const response = await axios.post(baseUrl, credentials)
  return response.data
}

const register = async (userinfo) => {
  const response = await axios.post('/api/users', userinfo)
  return response.data
}

const requestPasswordReset = async (userinfo) => {
  const response = await axios
    .post('/api/users/reset', userinfo)
    .catch((exception) => {
      return { data: `${exception.response.data.error}` }
    })
  return response
}

const resetPassword = async (userinfo) => {
  const response = await axios
    .post(`/api/users/reset/${userinfo.username}`, {
      token: userinfo.token,
      password: userinfo.password
    })
    .catch((exception) => {
      return { data: `${exception.response.data.error}` }
    })
  return response
}

const verifyEmail = async (userinfo) => {
  const response = await axios
    .post(`/api/users/verify/${userinfo.username}`, {
      token: userinfo.token
    })
    .catch((exception) => {
      return { data: `${exception.response.data.error}` }
    })
  return response
}

const requestEmailVerification = async (username) => {
  const response = await axios
    .post(`/api/users/verify/`, {
      username: username
    })
    .catch((exception) => {
      return { data: `${exception.response.data.error}` }
    })
  return response
}

const unsubscribeUser = async (token) => {
  const response = await axios
    .post(`/api/users/unsubscribe/`, {
      token: token
    })
    .catch((exception) => {
      return { data: `${exception.response.data.error}` }
    })
  return response
}

const loginService = {
  login,
  register,
  requestPasswordReset,
  resetPassword,
  verifyEmail,
  requestEmailVerification,
  unsubscribeUser
}

export default loginService
