import { Table, Form } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  deleteBasket,
  initializeBasket,
  setEmailUpdates
} from '../reducers/basketReducer'
import { getRetailerName, toPriceString } from '../utils'

const VerifyLink = ({ user }) => {
  if (user.verified) {
    return <></>
  }
  return (
    <p>
      <br />
      Your email is currently unverified, and you cannot receive weekly email
      updates until it is verified.
      <br />
      <Link to="/verify">Click here to verify your email</Link>.
      <br />
      <small>
        If you recently verified your email you can ignore this message.
      </small>
    </p>
  )
}

const BasketManager = () => {
  const user = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDeleteBasket = (event, id) => {
    event.preventDefault()
    dispatch(deleteBasket(id))
  }

  const handleChangeEmailStatus = (event, basket) => {
    event.preventDefault()
    if (!event.target.checked) {
      dispatch(setEmailUpdates(basket.id, false))
    } else {
      dispatch(setEmailUpdates(basket.id, true))
    }
  }

  const handleChangeBasket = async (basket) => {
    await dispatch(initializeBasket(basket))
    await window.localStorage.setItem('currentBasket', basket)
    navigate('/basket')
  }

  if (!user || !user.baskets) {
    return <></>
  }

  return (
    <div>
      <h3>Manage Baskets</h3>
      <Table striped hover bordered className="basketsTable">
        <thead>
          <tr>
            <th>Basket Name</th>
            <th># Items</th>
            <th>Current Price</th>
            <th>Email Updates?</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {user.baskets.map((b) => {
            return (
              <tr key={b.id}>
                <td
                  onClick={() => {
                    handleChangeBasket(b.id)
                  }}
                >
                  {b.name} ({getRetailerName(b)})
                </td>
                <td>{b?.products?.length || 0}</td>
                <td>
                  {toPriceString(
                    b?.products?.reduce(
                      (a, b) => a + b.prices[b.prices.length - 1].price,
                      0
                    ) || 0
                  )}
                </td>
                <td>
                  <Form.Check
                    checked={b.emailUpdates || false}
                    onChange={(event) => handleChangeEmailStatus(event, b)}
                  />
                </td>
                <td>
                  <Trash onClick={(event) => handleDeleteBasket(event, b.id)} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Link to="/basket/new">Create a New Basket</Link>
      <br />
      {(user.id === '6357b41339196ce58a6d8169' || //tracker
        user.id === '63caaaf5623fb67f18998bd7' || //dentsu
        user.id === '63f8ca5adb7f04260a0dc69b' ||
        user.id === '63f8ca94db7f04260a0dc6a3' ||
        user.id === '63f8cb91db7f04260a0dc6ac' ||
        user.id === '63f8cfeadb7f04260a0dee78' ||
        user.id === '63f8d01cdb7f04260a0dee80' ||
        user.id === '63f8d052db7f04260a0dee88' ||
        user.id === '63f8d09bdb7f04260a0dee90' ||
        user.id === '63fcb121390a6bf843650563' ||
        user.id === '63fcd03e390a6bf8436951a6') && (
        <Link to="/basket/duplicate">Copy a Basket</Link>
      )}
      <VerifyLink user={user} />
    </div>
  )
}

export default BasketManager
