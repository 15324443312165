import { useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Notification = () => {
  const notification = useSelector(({ notification }) => notification[0])
  const errorState = useSelector(({ notification }) => notification[1])
  const config = useSelector(({ notification }) => notification[2])
  const [outputNotification, setOutputNotification] = useState('')

  useEffect(() => {
    if (config && config.link) {
      setOutputNotification(<Link to={config.link}>{notification}</Link>)
    } else {
      setOutputNotification(notification)
    }
  }, [config, notification])

  if (notification) {
    return (
      <Alert variant={errorState ? 'danger' : 'success'}>
        {outputNotification}
      </Alert>
    )
  }
  return <div></div>
}

export default Notification
