import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import productService from '../services/product'
import { setError, setNotification } from '../reducers/notificationReducer'

const PriceForm = ({ code, storeID }) => {
  const [price, setPrice] = useState('')
  const [date, setDate] = useState('')
  const [size, setSize] = useState('')
  const [unitPrice, setUnitPrice] = useState('')
  const [priceType, setPriceType] = useState('')
  const [unit, setUnit] = useState('')
  const [quantity, setQuantity] = useState('')
  const [notes, setNotes] = useState('')
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user)

  const handleAddPrice = async (event) => {
    event.preventDefault()
    try {
      await productService.addPrice({
        code,
        storeID,
        price,
        date,
        size,
        unitPrice,
        priceType,
        unit,
        quantity,
        notes,
        token: user.token
      })
      dispatch(setNotification('price added'))
    } catch {
      dispatch(setError("couldn't add price"))
    }
  }

  return (
    <Col lg="6" md="8">
      <br />
      <h2>Add a Price</h2>
      <Form onSubmit={handleAddPrice}>
        <div>
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="text"
            value={price}
            name="price"
            id="price"
            onChange={({ target }) => setPrice(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>{'Date (yyyy-mm-dd)'}</Form.Label>
          <Form.Control
            type="text"
            value={date}
            name="date"
            id="date"
            onChange={({ target }) => setDate(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>Size</Form.Label>
          <Form.Control
            type="text"
            value={size}
            name="size"
            id="size"
            onChange={({ target }) => setSize(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>Unit Price</Form.Label>
          <Form.Control
            type="text"
            value={unitPrice}
            name="unitPrice"
            id="unitPrice"
            onChange={({ target }) => setUnitPrice(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>Price Type</Form.Label>
          <br />
          <select
            value={priceType}
            name="priceType"
            id="priceType"
            onChange={({ target }) => setPriceType(target.value)}
          >
            <option value="REGULAR">REGULAR</option>
            <option value="SPECIAL">SPECIAL</option>
          </select>
        </div>
        <br />
        <div>
          <Form.Label>Unit</Form.Label>
          <Form.Control
            type="text"
            value={unit}
            name="unit"
            id="unit"
            onChange={({ target }) => setUnit(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>Unit Quantity</Form.Label>
          <Form.Control
            type="text"
            value={quantity}
            name="quantity"
            id="quantity"
            onChange={({ target }) => setQuantity(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            type="text"
            value={notes}
            name="notes"
            id="notes"
            onChange={({ target }) => setNotes(target.value)}
          />
        </div>
        <br />
        <div>
          <br />
          <Button type="submit" id="add-price-button">
            Add Price
          </Button>
        </div>
      </Form>
    </Col>
  )
}

export default PriceForm
