import { Fragment, useEffect, useState } from 'react'
import basketService from '../services/basket'
import { Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  scales,
  TimeScale,
  LineController
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import chartTrendline from 'chartjs-plugin-trendline'
import { enUS } from 'date-fns/locale'
import { lineColors, toPriceString } from '../utils'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  scales,
  TimeScale,
  LineController
)
ChartJS.register(chartTrendline)

const ComparisonChart = ({ city }) => {
  const [data, setData] = useState(null)
  const basketIDs = {
    Calgary: [
      {
        id: '6357b42739196ce58a6d8171',
        name: 'Superstore',
        startDate: '2022-11-02 05:00:00'
      },
      {
        id: '6357ba7439196ce58a6d824d',
        name: 'No Frills',
        startDate: '2022-11-02 05:00:00'
      },
      {
        id: '63ee87b33c2dcad9d7202dc7',
        name: 'Walmart',
        startDate: '2023-03-01 05:00:00'
      },
      {
        id: '6362f80822dfdaf72e944701',
        name: 'Safeway',
        startDate: '2022-11-02 05:00:00'
      },
      {
        id: '6357be4039196ce58a6d833a',
        name: 'Save On Foods',
        startDate: '2022-11-02 05:00:00'
      },
      {
        id: '635c29a1bbaa981b2318b2c7',
        name: 'Calgary Coop',
        startDate: '2022-11-02 05:00:00'
      }
    ],
    Vancouver: [
      {
        id: '63b5f3b91d0f9a99fa2ff5c4',
        name: 'Superstore',
        startDate: '2023-05-24 05:00:00'
      },
      {
        id: '63b5f4691d0f9a99fa300881',
        name: 'No Frills',
        startDate: '2023-05-24 05:00:00'
      },
      {
        id: '646c0341c571fb9bd8ce5133',
        name: 'Walmart',
        startDate: '2023-05-24 05:00:00'
      },
      {
        id: '646d9179c571fb9bd8d9b874',
        name: 'Safeway',
        startDate: '2023-05-24 05:00:00'
      },
      {
        id: '63b5f5fb1d0f9a99fa301b39',
        name: 'Save On Foods',
        startDate: '2023-05-24 05:00:00'
      }
    ],
    Toronto: [
      {
        id: '63891f6343b7808306f526e7',
        name: 'Superstore',
        startDate: '2023-02-01 05:00:00'
      },
      {
        id: '63891f9b43b7808306f526f1',
        name: 'No Frills',
        startDate: '2023-02-01 05:00:00'
      },
      {
        id: '646c045dc571fb9bd8d004c5',
        name: 'Walmart',
        startDate: '2023-05-24 05:00:00'
      },
      {
        id: '638925fe43b7808306f5da2a',
        name: 'Safeway',
        startDate: '2023-02-01 05:00:00'
      },
      {
        id: '63891fdd43b7808306f526f6',
        name: 'Loblaws',
        startDate: '2023-02-01 05:00:00'
      }
    ]
  }
  const cities = ['Calgary', 'Vancouver', 'Toronto']
  const [selectedCity, setSelectedCity] = useState(null)

  useEffect(() => {
    const loadPrices = async () => {
      const newData = await Promise.all(
        basketIDs[selectedCity].map(async (id, i) => {
          const basket = await basketService.getBasket(id.id)
          const products = basket.products.map((product) => product.prices) //get a list of product prices
          const now = new Date()
          let priceList = []
          // let d = new Date('2022-11-02 05:00:00') //date to start tracking
          // if (selectedCity !== 'Calgary') {
          //   d = new Date('2023-02-01 05:00:00')
          // }
          // if (basket.retailer === 'walmart') {
          //   d = new Date('2023-03-01 05:00:00')
          // }
          let d = new Date(id.startDate)
          for (let i = 0; d <= now; i++) {
            const totalByDate = basketService.getTotalByDate(products, d)
            if (totalByDate[2]) priceList.push(totalByDate)
            d.setDate(d.getDate() + 7)
          }

          if (d.getDate() !== now.getDate()) {
            //add the current date
            const totalByDate = basketService.getTotalByDate(products, now)
            if (totalByDate[2]) priceList = priceList.concat([totalByDate])
          }

          return {
            label: basket.name,
            borderColor: lineColors[i].border,
            pointBackgroundColor: lineColors[i].border,
            pointHoverBackgroundColor: lineColors[i].bg,
            pointBorderWidth: 2,
            pointHoverBorderWidth: 1,
            spanGaps: 1209600000,
            data: priceList.map((x) => {
              return {
                x: x[0],
                y: parseFloat(x[1].reduce((a, b) => a + b.price, 0))
              }
            })
          }
        })
      )
      setData(newData)
      console.log(newData)
    }
    if (selectedCity) loadPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity])

  useEffect(() => {
    if (!localStorage.getItem('user-city')) {
      setSelectedCity(city)
    } else {
      setSelectedCity(localStorage.getItem('user-city'))
    }
  }, [city])
  if (!data) {
    return <></>
  }

  return (
    <div className="inflationTracker">
      <h4>
        Comparison Chart{' '}
        <Form.Select
          className="comparison-city-select"
          onChange={(e) => {
            setSelectedCity(e.target.value)
            localStorage.setItem('user-city', e.target.value)
          }}
          value={selectedCity}
        >
          {cities.map((city) => (
            <option value={city} key={city}>
              {city}
            </option>
          ))}
        </Form.Select>
      </h4>
      <p>
        Compare prices for a basket of 40 common items at{' '}
        {basketIDs[selectedCity].map((basket, i) => {
          const separator =
            basketIDs[selectedCity].length - 1 === i
              ? '.'
              : basketIDs[selectedCity].length - 2 === i
              ? ', & '
              : ', '
          return (
            <Fragment key={basket.id}>
              <Link to={`/basket/${basket.id}`}>{basket.name}</Link>
              {separator}{' '}
            </Fragment>
          )
        })}
      </p>
      <Chart
        type="line"
        options={{
          stepped: true,
          aspectRatio: 1.25,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return (
                    toPriceString(context.parsed.y) +
                    ' - ' +
                    context.dataset.label
                  )
                }
              }
            },
            legend: {
              display: true,
              position: 'bottom'
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'month',
                tooltipFormat: 'MMM dd, yyyy',
                displayFormats: {
                  week: 'MMM d, yy'
                }
              },
              adapters: {
                date: {
                  locale: enUS
                }
              }
            },
            y: {
              ticks: {
                // Format the ticks as a price $0.00
                callback: function (value, index, ticks) {
                  return toPriceString(value)
                },
                stepSize: 10
              }
            }
          }
        }}
        data={{
          datasets: data
        }}
      />
    </div>
  )
}

export default ComparisonChart
