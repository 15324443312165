import { Badge } from 'react-bootstrap'

const LowPriceBadge = ({ prices, isCurrent = true }) => {
  if (isCurrent) {
    const thirtyDays =
      new Date(prices[prices.length - 1].date).getTime() - 2592000000
    const ninetyDays =
      new Date(prices[prices.length - 1].date).getTime() - 7776000000
    const currentDate = new Date(prices[0].date).getTime()

    if (currentDate < thirtyDays) {
      // if we've been tracking for at least 30 days

      let lowestPrice30 = prices[prices.length - 1].price
      let i = prices.length - 1
      for (i; i > 0; i--) {
        if (new Date(prices[i].date).getTime() > thirtyDays) {
          if (prices[i].price < lowestPrice30) {
            lowestPrice30 = prices[i].price
            break
          }
        } else {
          break
        }
      }

      if (currentDate < ninetyDays) {
        let lowestPrice90 = lowestPrice30
        for (i; i > 0; i--) {
          if (new Date(prices[i].date).getTime() > ninetyDays) {
            if (prices[i].price < lowestPrice90) {
              lowestPrice90 = prices[i].price
              break
            }
          } else {
            break
          }
        }
        if (prices[prices.length - 1].price === lowestPrice90) {
          return <Badge bg="danger">Lowest price in 90+ days</Badge>
        }
      }
      if (prices[prices.length - 1].price === lowestPrice30) {
        return <Badge bg="warning">Lowest price in 30+ days</Badge>
      }
    }
  }
  return <></>
}

export default LowPriceBadge
