import { useEffect, useState } from 'react'
import basketService from '../services/basket'
import { Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  scales,
  TimeScale,
  LineController
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import chartTrendline from 'chartjs-plugin-trendline'
import { enUS } from 'date-fns/locale'
import { lineColors, toPriceString } from '../utils'
import { Link } from 'react-router-dom'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  scales,
  TimeScale,
  LineController
)
ChartJS.register(chartTrendline)

const InflationTracker = () => {
  const [data, setData] = useState(null)
  const [trackingItems, setTrackingItems] = useState(0)
  const basketID = '6328b3d344832f8f088be5a1'
  // const basketID = '6335cca31c72aebce10ef947'

  useEffect(() => {
    const loadPrices = async () => {
      const basket = await basketService.getBasket(basketID)
      const products = basket.products.map((product) => product.prices) //get a list of product prices
      const now = new Date()
      let priceList = []
      let d = new Date('2022-05-04') //date to start tracking
      for (let i = 0; d <= now; i++) {
        priceList[i] = basketService.getTotalByDate(products, d)
        d.setDate(d.getDate() + 7)
      }

      if (d.getDate() !== now.getDate()) {
        //add the current date
        priceList = priceList.concat([
          basketService.getTotalByDate(products, now)
        ])
      }

      const data = [
        {
          label: 'Grocery Prices',
          borderColor: lineColors[0].border,
          pointBackgroundColor: lineColors[0].border,
          pointHoverBackgroundColor: lineColors[0].bg,
          pointBorderWidth: 2,
          pointHoverBorderWidth: 1,
          data: priceList.map((x) => {
            return {
              x: x[0],
              y: parseFloat(x[1].reduce((a, b) => a + b.price, 0))
            }
          }),
          trendlineLinear: {
            colorMin: lineColors[2].border,
            colorMax: lineColors[2].border,
            lineStyle: 'dotted',
            width: 2,
            projection: true
          }
        }
      ]
      setTrackingItems(products.length)
      setData(data)
    }
    loadPrices()
  }, [])

  if (!data) {
    return <></>
  }

  const inflationPercentage = Math.round(
    ((data[0].data[data[0].data.length - 1].y - data[0].data[0].y) /
      data[0].data[0].y) *
      100
  )

  return (
    <div className="inflationTracker">
      <h4>Inflation Tracker</h4>
      <p>
        Price history of {trackingItems}{' '}
        <Link to={`/basket/${basketID}`}>common grocery items</Link>
      </p>
      <p>
        The the price of these items is {inflationPercentage}% more than on May
        1, 2022
      </p>
      <Chart
        type="line"
        options={{
          stepped: true,
          aspectRatio: 1.75,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return toPriceString(context.parsed.y)
                }
              }
            },
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'month',
                tooltipFormat: 'MMM dd, yyyy'
              },
              adapters: {
                date: {
                  locale: enUS
                }
              }
            },
            y: {
              ticks: {
                // Format the ticks as a price $0.00
                callback: function (value, index, ticks) {
                  return toPriceString(value)
                }
              }
            }
          }
        }}
        data={{
          datasets: data
        }}
      />
    </div>
  )
}

export default InflationTracker
