import coopService from './services/coop'
import pcService from './services/pc'
import saveonService from './services/saveon'
import voilaService from './services/voila'
import walmartService from './services/walmart'

export const toPriceString = (price) => {
  return '$' + Number.parseFloat(price).toFixed(2)
}

export const retailerList = {
  superstore: {
    name: 'Superstore',
    trackerLink: '/',
    retailLink: 'https://realcanadiansuperstore.ca/p/',
    service: pcService
  },
  nofrills: {
    name: 'No Frills',
    trackerLink: '/',
    retailLink: 'https://nofrills.ca/p/',
    service: pcService
  },
  saveon: {
    name: 'Save-On-Foods',
    trackerLink: '/saveon/',
    retailLink: 'https://saveonfoods.com/sm/pickup/rsid/%store%/product/',
    service: saveonService
  },
  loblaw: {
    name: 'Loblaws',
    trackerLink: '/',
    retailLink: 'https://loblaws.ca/p/',
    service: pcService
  },
  maxi: {
    name: 'Maxi',
    trackerLink: '/',
    retailLink: 'https://maxi.ca/p/',
    service: pcService
  },
  fortinos: {
    name: 'Fortinos',
    trackerLink: '/',
    retailLink: 'https://fortinos.ca/p/',
    service: pcService
  },
  valumart: {
    name: 'Valu-Mart',
    trackerLink: '/',
    retailLink: 'https://valumart.ca/p/',
    service: pcService
  },
  zehrs: {
    name: 'Zehrs',
    trackerLink: '/',
    retailLink: 'https://zehrs.ca/p/',
    service: pcService
  },
  rass: {
    name: 'Atlantic Superstore',
    trackerLink: '/',
    retailLink: 'https://atlanticsuperstore.ca/p/',
    service: pcService
  },
  wholesaleclub: {
    name: 'Wholesale Club',
    trackerLink: '/',
    retailLink: 'https://wholesaleclub.ca/p/',
    service: pcService
  },
  provigo: {
    name: 'Provigo',
    trackerLink: '/',
    retailLink: 'https://provigo.ca/p/',
    service: pcService
  },
  voila: {
    name: 'Safeway/Sobeys',
    trackerLink: '/voila/',
    retailLink: 'https://voila.ca/products/',
    service: voilaService
  },
  coop: {
    name: 'Calgary Co-op',
    trackerLink: '/coop/',
    retailLink: 'https://shoponline.calgarycoop.com/%shortstorename%#/product/',
    service: coopService
  },
  walmart: {
    name: 'Walmart',
    trackerLink: '/walmart/',
    retailLink: 'https://www.walmart.ca/en/ip/',
    service: walmartService
  }
}

export const getTrackerLink = (product) => {
  return `/product${retailerList[product.retailer]?.trackerLink}${
    product.storeID
  }/${product.code}`
}

export const getRetailerName = (product) => {
  return retailerList[product.retailer]?.name
}

export const lineColors = [
  { bg: '#212529', border: '#dc3545' },
  { bg: '#212529', border: '#6f42c1' },
  { bg: '#212529', border: '#0d6efd' },
  { bg: '#212529', border: '#198754' },
  { bg: '#212529', border: '#fd7e14' },
  { bg: '#212529', border: '#d63384' }
]

export const isCurrentPrice = (price) => {
  if (!price) return true
  const target = 4 // Thursday
  let date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() - ((date.getDay() + 7 - target) % 7))
  return date.getTime() - 43200000 <= new Date(price.date).getTime() // subtract 12 hours (43,200,000ms) to account for time zone difference
}

export const getConfig = () => {
  return {
    headers: {
      Trackerkey: '89nSDFJp340O9fjs430'
    }
  }
}
