import { Component } from 'react'

export default class GoogleAdSense extends Component {
  componentDidMount() {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  render() {
    const { className, slot, format } = this.props

    return (
      <div className="GoogleAdSense">
        <ins
          className={`adsbygoogle ${className}`}
          style={{ display: 'block' }}
          data-ad-client="ca-pub-8760827679418777"
          data-ad-slot={slot}
          data-ad-format={format}
        ></ins>
      </div>
    )
  }
}
