import axios from 'axios'
import { getConfig } from '../utils'

const baseUrl = '/api/saveon'

const getProduct = async (product, store) => {
  const req = await axios.get(`${baseUrl}/${store}/${product}`, getConfig())
  return req.data
}

const search = async (store, term) => {
  const req = await axios.get(`${baseUrl}/search/${store}/${term}`, getConfig())
  return req.data
}

const saveonService = { getProduct, search }

export default saveonService
