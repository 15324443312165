import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setError, setNotification } from '../reducers/notificationReducer'
import loginService from '../services/login'

const PasswordResetRequest = () => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()

  const handleResetRequest = async (event) => {
    event.preventDefault()
    const request = await loginService.requestPasswordReset({
      username: username,
      email: email
    })
    if (request?.status === 200) {
      dispatch(
        setNotification(
          'Request sent. If this is a valid account with an email address, a reset email will be sent to your inbox'
        )
      )
    } else {
      dispatch(setError(request.data))
    }
  }

  return (
    <Form onSubmit={handleResetRequest}>
      <h5>Please enter your username OR email address</h5>
      <br />
      <div>
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          value={username}
          name="Username"
          id="username"
          onChange={({ target }) => setUsername(target.value)}
        />
      </div>
      <br />
      <div>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          value={email}
          name="Email"
          id="email"
          onChange={({ target }) => setEmail(target.value)}
        />
      </div>
      <br />
      <Button type="submit" id="login-button">
        Request Password Reset
      </Button>
      <p> </p>
    </Form>
  )
}

export default PasswordResetRequest
