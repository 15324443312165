import axios from 'axios'
const baseUrl = '/api/stores'

const getStores = async (retailer) => {
  const response = await axios.get(`${baseUrl}/${retailer}`)
  const filteredStores = response.data.flatMap((store) =>
    !('visible' in store) || !('isShoppable' in store) || store.visible
      ? {
          id: store.id,
          name: store.name,
          latt: store.geoPoint.latitude,
          long: store.geoPoint.longitude,
          region: store.address.region,
          shortname: store?.shortname
        }
      : []
  )
  return filteredStores.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  ) //sort alphabetically
}

const storesService = { getStores }

export default storesService
