import { configureStore } from '@reduxjs/toolkit'
import basketReducer from './reducers/basketReducer'
import notificationReducer from './reducers/notificationReducer'
import storeReducer from './reducers/storeReducer'
import storesReducer from './reducers/storesReducer'
import userReducer from './reducers/userReducer'

const store = configureStore({
  reducer: {
    notification: notificationReducer,
    user: userReducer,
    basket: basketReducer,
    store: storeReducer,
    stores: storesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 }
    })
})

export default store
