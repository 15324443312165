import { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { useSelector } from 'react-redux'
import { retailerList } from '../utils'
import Location from './Location'

const StoreSelect = ({
  store,
  setStore,
  retailer,
  setRetailer,
  retailerFilter
}) => {
  const storeFromRedux = useSelector(({ store }) => store)
  const stores = useSelector(({ stores }) => stores)
  const retailers = retailerFilter ? retailerFilter : retailerList

  useEffect(() => {
    if (!stores?.[retailer]?.find((s) => s.id === store)) {
      if (!store && storeFromRedux && storeFromRedux.retailer) {
        setRetailer(storeFromRedux.retailer)
      } else if (store.length && store.retailer) {
        setRetailer(store.retailer)
      } else if (store.length) {
        const retailer = Object.entries(stores).filter(([retailer, values]) =>
          values.find((s) => {
            return store === s.id
          })
        )
        if (retailer?.[0]?.[0]) {
          setRetailer(retailer?.[0]?.[0])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFromRedux, setRetailer, store, stores])
  return (
    <>
      <Form.Select
        onChange={(e) => setRetailer(e.target.value)}
        value={retailer}
      >
        {Object.keys(retailers).map((retailer) => (
          <option value={retailer} key={retailer}>
            {retailers[retailer].name}
          </option>
        ))}
      </Form.Select>
      <Form.Select onChange={(e) => setStore(e.target.value)} value={store}>
        <option value="">Select a Store... (click pin to sort)</option>
        {stores[retailer]?.map((s) => (
          <option value={s.id} key={s.id}>
            {s.name}
          </option>
        ))}
      </Form.Select>
      <Location />
    </>
  )
}

export default StoreSelect
