import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { registerUser } from '../reducers/userReducer'
import { Form, Button, Stack } from 'react-bootstrap'
import StoreSelect from './StoreSelect'
import { QuestionCircle } from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const Register = () => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [favouriteStore, setFavouriteStore] = useState('')
  const [favouriteRetailer, setFavouriteRetailer] = useState('superstore')
  const [updates, setUpdates] = useState(false)
  const [password, setPassword] = useState('')
  const handleRegister = (event) => {
    event.preventDefault()
    dispatch(
      registerUser(
        name,
        username,
        email,
        favouriteStore,
        favouriteRetailer,
        updates,
        password,
        setName,
        setUsername,
        setEmail,
        setPassword
      )
    )
  }

  const nameTooltip = (props) => (
    <Tooltip {...props}>
      Last name is optional. Use your real first name. Or don't. But you do need
      to put something.
    </Tooltip>
  )
  const usernameTooltip = (props) => (
    <Tooltip {...props}>Must contain at least 4 letters or numbers.</Tooltip>
  )
  const emailTooltip = (props) => (
    <Tooltip {...props}>
      In case you forget your password, and to receive optional weekly email
      updates.
    </Tooltip>
  )
  const storeTooltip = (props) => (
    <Tooltip {...props}>
      Prices may vary from store to store, so choose a store nearby for more
      accurate information. Your first basket will use this store.
    </Tooltip>
  )
  const updatesTooltip = (props) => (
    <Tooltip {...props}>
      Do you want to receive email updates when the products you are watching go
      on sale?
    </Tooltip>
  )
  const passwordTooltip = (props) => (
    <Tooltip {...props}>
      Choose a strong password at least 4 characters long.
    </Tooltip>
  )

  return (
    <Form onSubmit={handleRegister}>
      <div>
        <Form.Label>
          Name{' '}
          <OverlayTrigger placement="top" overlay={nameTooltip}>
            <QuestionCircle />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          value={name}
          name="Name"
          id="name"
          onChange={({ target }) => setName(target.value)}
        />
      </div>
      <br />
      <div>
        <Form.Label>
          Username{' '}
          <OverlayTrigger placement="top" overlay={usernameTooltip}>
            <QuestionCircle />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          value={username}
          name="Username"
          id="regUsername"
          onChange={({ target }) => setUsername(target.value)}
        />
      </div>
      <br />
      <div>
        <Form.Label>
          Email{' '}
          <OverlayTrigger placement="top" overlay={emailTooltip}>
            <QuestionCircle />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          value={email}
          name="Email"
          id="email"
          onChange={({ target }) => setEmail(target.value)}
        />
      </div>
      <br />
      <div>
        <Form.Label>
          Favourite Store{' '}
          <OverlayTrigger placement="top" overlay={storeTooltip}>
            <QuestionCircle />
          </OverlayTrigger>
        </Form.Label>
        <Stack direction="horizontal" gap={2}>
          <StoreSelect
            store={favouriteStore}
            name="FavouriteStore"
            id="favouriteStore"
            setStore={setFavouriteStore}
            retailer={favouriteRetailer}
            setRetailer={setFavouriteRetailer}
          />
        </Stack>
      </div>
      <br />
      <div>
        <Form.Label>
          Receive Email Updates?{' '}
          <OverlayTrigger placement="top" overlay={updatesTooltip}>
            <QuestionCircle />
          </OverlayTrigger>
        </Form.Label>
        <Form.Check
          checked={updates}
          name="updates"
          id="updates"
          onChange={({ target }) => setUpdates(target.checked)}
        />
      </div>
      <br />
      <div>
        <Form.Label>
          Password{' '}
          <OverlayTrigger placement="top" overlay={passwordTooltip}>
            <QuestionCircle />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="password"
          value={password}
          name="Password"
          id="regPassword"
          onChange={({ target }) => setPassword(target.value)}
        />
      </div>
      <br />
      <Button type="submit" id="register-button">
        Register
      </Button>
    </Form>
  )
}

export default Register
