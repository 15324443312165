import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import ProductCard from './ProductCard'
import { useParams } from 'react-router-dom'
import { setTheStore } from '../reducers/storeReducer'
import { useDispatch } from 'react-redux'
import { retailerList } from '../utils'
import { setError } from '../reducers/notificationReducer'

const SearchResults = ({ retailer }) => {
  const [searchResults, setSearchResults] = useState({})
  const term = useParams().term
  const store = useParams().store
  const dispatch = useDispatch()

  useEffect(() => {
    const doSearch = async () => {
      try {
        const retailerService = retailerList[retailer || 'superstore'].service
        const result = await retailerService.search(store, term)
        setSearchResults(result)
        dispatch(
          setTheStore({
            id: store,
            retailer: retailer
              ? retailer
              : result.results[0]
              ? result.results[0].retailer
              : null
          })
        )
      } catch (e) {
        dispatch(setError('Request timed out. Please reload to try again.'))
      }
    }
    if (store && term) {
      doSearch()
    }
  }, [store, term, retailer, dispatch])

  if (!Object.keys(searchResults).length) {
    if (!term) return <></>
    else return <p>Loading...</p>
  }
  if (!Object.keys(searchResults.results).length) {
    return <div>No results found.</div>
  }

  return (
    <div>
      {
        //if terms don't match or stores don't match, then results are still loading
        ((searchResults?.query !== term &&
          searchResults.correctedQuery !== term) ||
          searchResults?.results?.[0]?.storeID !== store) && <p>Loading...</p>
      }
      <h3>
        Search results for "
        {searchResults.correctedQuery || searchResults.query}"
      </h3>
      <Row xs={2} sm={2} md={3} lg={4} xl={5} className="g-2">
        {searchResults.results
          .filter((p) => p.prices.price !== 0)
          .map((p) => {
            return (
              <ProductCard
                product={p}
                regPrice={p.prices.wasPrice || null}
                price={p.prices.price}
                sale={p.prices.priceType !== 'REGULAR'}
                key={p.code}
                image={p.image || null}
              />
            )
          })}
      </Row>
    </div>
  )
}

export default SearchResults
