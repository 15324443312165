/* Service for adding, modifying products in the DB */
import axios from 'axios'
import { toPriceString, getConfig } from '../utils'
const baseUrl = '/api/products'

const add = async (newProduct, store) => {
  const response = await axios.post(
    baseUrl,
    { code: newProduct, store: store },
    getConfig()
  )
  return response.data
}

const update = async (id, newProduct) => {
  const response = await axios.put(`${baseUrl}/${id}`, newProduct, getConfig())
  return response.data
}

const deleteProduct = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`, getConfig())
  return response.data
}

const addPrice = async (params) => {
  const config = {
    headers: {
      Authorization: `bearer ${params.token}`,
      TrackerKey: '89nSDFJp340O9fjs430' + new Date().getDay()
    }
  }
  const response = await axios.put(
    baseUrl,
    {
      code: params.code,
      storeID: params.storeID,
      price: params.price,
      date: params.date,
      size: params.size,
      unit: params.unit,
      priceType: params.priceType,
      unitPriceValue: params.unitPrice,
      unitPriceUnit: params.unit,
      unitPriceQuantity: params.quantity,
      notes: params.notes
    },
    config
  )
  return response.data
}

const getUnitPrice = (price, toString = true) => {
  const units = [
    //[display unit, quantiy, [variations], multiplier (if different than quantity)]
    ['each', '', ['ea', 'EA', 'each'], 1],
    ['g', 100, ['g', 'GR', 'gram', 'grams', 'G']],
    ['g', 100, ['kg', 'KG', 'kilogram'], 0.1],
    ['ml', 100, ['ml', 'ML', 'millilitre', 'mL']],
    ['ml', 100, ['l', 'LT', 'L', 'litre'], 0.1],
    ['g', 100, ['lb', 'LB', 'pound'], 0.220462]
  ]

  try {
    const priceUnit = price.size.split(' ')[1] || price.unit
    const unit = units.find((u) => u[2].includes(priceUnit)) || units[0]
    const unitPriceQuantity = unit[1]
    let quantity = price.size.split(' ')[0].match(/(\d|\.)+/)[0] || 1
    if (price.size.includes('x')) {
      const x = price.size.split(' ')[0].split('x')
      quantity = x[0] * x[1]
    }

    return toString
      ? toPriceString(
          (price.price / quantity) * (unit[3] || unitPriceQuantity)
        ) +
          ' / ' +
          unitPriceQuantity +
          unit[0]
      : (price.price / quantity) * (unit[3] || unitPriceQuantity)
  } catch {
    return toString
      ? price.unitPriceValue && price.unitPriceQuantity && price.unitPriceUnit
        ? `${toPriceString(price.unitPriceValue)} / ${price.unitPriceQuantity}${
            price.unitPriceUnit
          }`
        : ''
      : price.unitPriceValue
      ? price.unitPriceValue
      : ''
  }
}

const productService = { add, update, deleteProduct, addPrice, getUnitPrice }

export default productService
