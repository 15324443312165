import { createSlice } from '@reduxjs/toolkit'
import basketService from '../services/basket'
import { setError } from './notificationReducer'
import { setUser } from './userReducer'

const basketSlice = createSlice({
  name: 'basket',
  initialState: null,
  reducers: {
    setBasket(state, action) {
      return action.payload
    },
    clearBasket() {
      return null
    }
  }
})

export const { setBasket, clearBasket } = basketSlice.actions

export const initializeBasket = (id) => {
  return async (dispatch) => {
    const basket = await basketService.getBasket(id)
    dispatch(setBasket(basket))
  }
}

export const addToBasket = (product) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().user
      const basketId = getState().basket.id
      const store = getState().store.id
      const retailer = getState().store.retailer || getState().basket.retailer
      const token = getState().user.token
      const basket = await basketService.addProduct(
        basketId,
        product,
        store,
        retailer,
        token
      )
      dispatch(setBasket(basket))

      //update user
      const newBaskets = [...user.baskets]
      newBaskets[newBaskets.findIndex((b) => b.id === basket.id)] = basket
      const newUser = { ...user, baskets: newBaskets }
      dispatch(setUser(newUser))
      window.localStorage.setItem('loggedInUser', JSON.stringify(newUser))
    } catch {
      dispatch(setError('Must be logged in to add product to a basket'))
    }
  }
}

export const removeFromBasket = (product) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().user
      const basketId = getState().basket.id
      const store = getState().store.id
      const retailer = getState().store.retailer || getState().basket.retailer
      const token = getState().user.token
      const basket = await basketService.removeProduct(
        basketId,
        product,
        store,
        retailer,
        token
      )
      dispatch(setBasket(basket))

      //update user
      const newBaskets = [...user.baskets]
      newBaskets[newBaskets.findIndex((b) => b.id === basket.id)] = basket
      const newUser = { ...user, baskets: newBaskets }
      dispatch(setUser(newUser))
      window.localStorage.setItem('loggedInUser', JSON.stringify(newUser))
    } catch {
      dispatch(setError('Must be logged in to remove product from basket'))
    }
  }
}

export const createBasket = (name, store, retailer, updates) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().user

      if (
        user.baskets.length > 4 &&
        user.id !== '6357b41339196ce58a6d8169' && //tracker
        user.id !== '63caaaf5623fb67f18998bd7' && //dentsu
        user.id !== '63f8ca5adb7f04260a0dc69b' &&
        user.id !== '63f8ca94db7f04260a0dc6a3' &&
        user.id !== '63f8cb91db7f04260a0dc6ac' &&
        user.id !== '63f8cfeadb7f04260a0dee78' &&
        user.id !== '63f8d01cdb7f04260a0dee80' &&
        user.id !== '63f8d052db7f04260a0dee88' &&
        user.id !== '63f8d09bdb7f04260a0dee90' &&
        user.id === '63fcb121390a6bf843650563' &&
        user.id === '63fcd03e390a6bf8436951a6'
      ) {
        dispatch(
          setError('Sorry, accounts are limited to a maximum of 5 baskets')
        )
      } else {
        const token = user.token
        const basket = await basketService.create(
          name,
          user.id,
          store,
          retailer,
          updates,
          token
        )
        const newUser = { ...user, baskets: user.baskets.concat(basket) }
        window.localStorage.setItem('currentBasket', basket.id)
        dispatch(setUser(newUser))
        window.localStorage.setItem('loggedInUser', JSON.stringify(newUser))
        // dispatch(setBasket(basket))
      }
    } catch {
      dispatch(setError('Must be logged in to add product to a basket'))
    }
  }
}

export const deleteBasket = (id) => {
  return async (dispatch, getState) => {
    if (window.confirm('Are you sure you want to delete this basket?')) {
      try {
        const user = getState().user

        if (user.baskets.length === 1) {
          dispatch(setError('You must always have at least one basket'))
        } else {
          const token = user.token
          await basketService.deleteBasket(id, token)

          const newUser = {
            ...user,
            baskets: user.baskets.filter((b) => b.id !== id)
          }
          if (getState().basket.id === id) {
            window.localStorage.setItem(
              'currentBasket',
              getState().user.baskets[0].id
            )
          }
          dispatch(setUser(newUser))
          window.localStorage.setItem('loggedInUser', JSON.stringify(newUser))
        }
      } catch (error) {
        dispatch(setError('Must be logged in to delete a basket'))
      }
    } else {
      return false
    }
  }
}

export const setEmailUpdates = (id, emailUpdates) => {
  return async (dispatch, getState) => {
    try {
      const token = getState().user.token
      const basket = await basketService.changeEmailUpdates(
        id,
        emailUpdates,
        token
      )
      const user = getState().user
      const newUser = {
        ...user,
        baskets: user.baskets.map((b) => {
          if (b.id === id) return basket
          else return b
        })
      }
      dispatch(setUser(newUser))
    } catch {
      dispatch(setError('Must be logged in to add product to a basket'))
    }
  }
}

export default basketSlice.reducer
