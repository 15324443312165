import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import {
  BagDashFill,
  BagPlus,
  ZoomIn,
  Link45deg,
  GraphUpArrow,
  CashCoin,
  ArrowDownSquare
} from 'react-bootstrap-icons'
import InflationTracker from './InflationTracker'
import ComparisonChart from './ComparisonChart'
import SearchForm from './SearchForm'

const Home = ({ city }) => {
  return (
    <div className="homepage">
      <SearchForm />
      <p className="lead">
        Welcome to Grocery Tracker, a useful tool for saving money on your next
        trip to the grocery store!
      </p>

      <h4>
        <GraphUpArrow /> View Price History
      </h4>
      <p>
        See historic pricing for products at your local store and other nearby
        stores. If a product doesn't have a recorded price history, just add it
        to a basket and we'll start tracking prices going forward.
      </p>

      <h4>
        <ArrowDownSquare /> Watch Items &amp; See When They Go On Sale
      </h4>
      <p>
        Add items to a basket and then check back every week to see if they've
        gone on sale (or get an optional email alert!). Create a basket for
        kitchen staples that you want to stock up on when they're cheap, and
        then wait for a notification that they've dropped to a reasonable price.
      </p>

      <h4>
        <CashCoin /> Compare Prices With Other Stores
      </h4>
      <p>
        Search to see how much a product costs at a different store to ensure
        you are getting the best deal. Or create identical baskets for two
        stores and see which store has the best price for your shopping trip
        this week!
      </p>

      <p>
        Ready to get started? <Link to="/login">Create a free account</Link> or{' '}
        <Link to="/search">start searching now!</Link>
      </p>

      <hr />

      <Row className="home-icons">
        <Col sm={4}>
          <ZoomIn />
          <p>View item details and price history</p>
        </Col>
        <Col sm={4}>
          <BagPlus />
          <BagDashFill />
          <p>Add or remove item from your basket</p>
        </Col>
        <Col sm={4}>
          <Link45deg />
          <p>Buy item at the grocery store's website</p>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col sm={6}>
          <InflationTracker />
        </Col>
        <Col sm={6}>
          <ComparisonChart city={city} />
        </Col>
      </Row>
    </div>
  )
}

export default Home
