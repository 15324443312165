import { useEffect, useState } from 'react'
import SearchForm from './components/SearchForm'
import SearchResults from './components/SearchResults'
import Product from './components/Product'
import Navigation from './components/Navigation'
import Notification from './components/Notification'
import Basket from './components/Basket'
import NewBasket from './components/NewBasket'
import Footer from './components/Footer'
import { initializeUser } from './reducers/userReducer'
import { initializeStore } from './reducers/storeReducer'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initializeBasket } from './reducers/basketReducer'
import './App.css'
import LoginPage from './components/Login'
import Home from './components/Home'
import AuthVerification from './components/AuthVerification'
import PasswordResetRequest from './components/PasswordResetRequest'
import PasswordReset from './components/PasswordReset'
import BasketManager from './components/BasketManager'
import EmailVerification from './components/EmailVerification'
import UnsubscribeUser from './components/UnsubscribeUser'
import { retailerList } from './utils'
import storesService from './services/stores'
import { initializeStores } from './reducers/storesReducer'
import locationService from './services/location'
import DuplicateBasket from './components/DuplicateBasket'

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user)
  const basket = useSelector(({ basket }) => basket)
  const [city, setCity] = useState(null)

  useEffect(() => {
    dispatch(initializeUser())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      window.localStorage.getItem('currentBasket')
        ? dispatch(
            initializeBasket(window.localStorage.getItem('currentBasket'))
          )
        : dispatch(initializeBasket(user.baskets[0]?.id || null))
    }
  }, [dispatch, user])

  useEffect(() => {
    if (basket) {
      dispatch(
        initializeStore({ id: basket.storeID, retailer: basket.retailer })
      )
    }
  }, [dispatch, basket])

  useEffect(() => {
    const getStores = async () => {
      const stores = await Promise.all(
        Object.keys(retailerList).map(async (retailer) => [
          retailer,
          await storesService.getStores(retailer)
        ])
      ).then(Object.fromEntries)
      if (localStorage.getItem('user-location')) {
        let loc = localStorage.getItem('user-location')
        const coords = /-?[0-9]+.[0-9]+,-?[0-9]+.[0-9]+/
        if (coords.test(loc)) {
          loc = loc.split(',')
          const sortedStores = locationService.sortAllByLocation(
            stores,
            loc[0],
            loc[1]
          )
          dispatch(initializeStores(sortedStores))
        }
        if (!localStorage.getItem('user-city')) {
          const location = await locationService.getLocationByIP()
          localStorage.setItem('user-city', location.city)
          setCity(location.city)
        }
      } else {
        try {
          const loc = await locationService.getLocationByIP()
          const sortedStores = locationService.sortAllByLocation(
            stores,
            loc.coords.latitude,
            loc.coords.longitude
          )
          dispatch(initializeStores(sortedStores))
          localStorage.setItem(
            'user-location',
            `${loc.coords.latitude},${loc.coords.longitude}`
          )
          if (!localStorage.getItem('user-city')) {
            localStorage.setItem('user-city', loc.city)
            setCity(loc.city)
          }
        } catch (e) {
          dispatch(initializeStores(stores))
        }
      }
      if (!city) {
        setCity('Toronto')
      }
    }
    getStores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <div>
      <Navigation />
      <div className="container main-content">
        <Notification />
        <Routes>
          <Route path="/" element={<Home city={city} />} />
          <Route
            path="/search/"
            element={
              <div>
                <SearchForm />
                <SearchResults />
              </div>
            }
          />
          <Route
            path="/search/saveon"
            element={
              <div>
                <SearchForm retailer="saveon" />
                <SearchResults retailer="saveon" />
              </div>
            }
          />
          <Route
            path="/search/saveon/:store"
            element={
              <div>
                <SearchForm retailer="saveon" />
                <SearchResults retailer="saveon" />
              </div>
            }
          />
          <Route
            path="/search/saveon/:store/:term"
            element={
              <div>
                <SearchForm retailer="saveon" />
                <SearchResults retailer="saveon" />
              </div>
            }
          />
          <Route
            path="/search/voila"
            element={
              <div>
                <SearchForm retailer="voila" />
                <SearchResults retailer="voila" />
              </div>
            }
          />
          <Route
            path="/search/voila/:store"
            element={
              <div>
                <SearchForm retailer="voila" />
                <SearchResults retailer="voila" />
              </div>
            }
          />
          <Route
            path="/search/voila/:store/:term"
            element={
              <div>
                <SearchForm retailer="voila" />
                <SearchResults retailer="voila" />
              </div>
            }
          />
          <Route
            path="/search/coop"
            element={
              <div>
                <SearchForm retailer="coop" />
                <SearchResults retailer="coop" />
              </div>
            }
          />
          <Route
            path="/search/coop/:store"
            element={
              <div>
                <SearchForm retailer="coop" />
                <SearchResults retailer="coop" />
              </div>
            }
          />
          <Route
            path="/search/coop/:store/:term"
            element={
              <div>
                <SearchForm retailer="coop" />
                <SearchResults retailer="coop" />
              </div>
            }
          />
          <Route
            path="/search/walmart"
            element={
              <div>
                <SearchForm retailer="walmart" />
                <SearchResults retailer="walmart" />
              </div>
            }
          />
          <Route
            path="/search/walmart/:store"
            element={
              <div>
                <SearchForm retailer="walmart" />
                <SearchResults retailer="walmart" />
              </div>
            }
          />
          <Route
            path="/search/walmart/:store/:term"
            element={
              <div>
                <SearchForm retailer="walmart" />
                <SearchResults retailer="walmart" />
              </div>
            }
          />
          <Route
            path="/search/:store"
            element={
              <div>
                <SearchForm />
                <SearchResults />
              </div>
            }
          />
          <Route
            path="/search/:store/:term"
            element={
              <div>
                <SearchForm />
                <SearchResults />
              </div>
            }
          />
          <Route
            path="/login"
            element={
              user ? (
                new URLSearchParams(window.location.search).get('redirect') ? (
                  <Navigate
                    replace
                    to={new URLSearchParams(window.location.search).get(
                      'redirect'
                    )}
                  />
                ) : (
                  <Navigate replace to="/basket" />
                )
              ) : (
                <LoginPage />
              )
            }
          />
          <Route path="/product/:store/:code" element={<Product />} />
          <Route
            path="/product/saveon/:store/:code"
            element={<Product retailer="saveon" />}
          />
          <Route
            path="/product/voila/:store/:code"
            element={<Product retailer="voila" />}
          />
          <Route
            path="/product/coop/:store/:code"
            element={<Product retailer="coop" />}
          />
          <Route
            path="/product/walmart/:store/:code"
            element={<Product retailer="walmart" />}
          />
          <Route path="/basket/:basket" element={<Basket />} />
          <Route path="/basket/new" element={<NewBasket />} />
          <Route path="/basket/duplicate" element={<DuplicateBasket />} />
          <Route path="/basket/" element={<Basket />} />
          <Route path="/baskets/" element={<BasketManager />} />
          <Route path="/reset/:username/:token" element={<PasswordReset />} />
          <Route path="/reset/" element={<PasswordResetRequest />} />
          <Route
            path="/verify/:username/:token"
            element={<EmailVerification />}
          />
          <Route path="/verify/" element={<EmailVerification />} />
          <Route path="/unsubscribe/:token" element={<UnsubscribeUser />} />
        </Routes>
        <AuthVerification />
      </div>
      <Footer />
    </div>
  )
}

export default App
