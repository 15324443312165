import { useEffect, useState } from 'react'
import { Form, Button, Stack, Row } from 'react-bootstrap'
import StoreSelect from './StoreSelect'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import { retailerList } from '../utils'
import { setError } from '../reducers/notificationReducer'
import { setUser } from '../reducers/userReducer'
import basketService from '../services/basket'
import ProductCard from './ProductCard'

const DuplicateBasket = () => {
  const [basketName, setBasketName] = useState('')
  const [basketStore, setBasketStore] = useState('')
  const [basketRetailer, setBasketRetailer] = useState('superstore')
  const [selectedBasket, setSelectedBasket] = useState('')
  const [selectedRetailer, setSelectedRetailer] = useState('superstore')
  const [errorList, setErrorList] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user)
  let retailers = retailerList

  const handleDuplicateBasket = async (event) => {
    event.preventDefault()
    setLoading(true)
    if (selectedBasket !== '') {
      try {
        const token = user.token
        const basket = await basketService.duplicate(
          basketName,
          user.id,
          basketStore,
          basketRetailer,
          selectedBasket,
          token
        )
        const newUser = { ...user, baskets: user.baskets.concat(basket[0]) }
        window.localStorage.setItem('currentBasket', basket[0].id)
        dispatch(setUser(newUser))
        window.localStorage.setItem('loggedInUser', JSON.stringify(newUser))
        setErrorList(basket[1])
      } catch (e) {
        dispatch(setError('Must be logged in to add product to a basket'))
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (selectedBasket && user) {
      setSelectedRetailer(
        user.baskets.find((basket) => selectedBasket === basket.id).retailer
      )
      setBasketRetailer(
        user.baskets.find((basket) => selectedBasket === basket.id).retailer
      )
    }
  }, [selectedBasket, user])

  if (Object.keys(retailers).length) {
    if (retailers[selectedRetailer].trackerLink === '/') {
      let newRetailerList = Object.entries(retailers).filter(
        ([key, value]) => value.trackerLink === '/'
      )
      retailers = Object.fromEntries(newRetailerList)
    } else {
      let newRetailerList = {}
      newRetailerList[selectedRetailer] = { ...retailers[selectedRetailer] }
      retailers = newRetailerList
    }
  }

  if (!user) return <></>

  if (errorList === null) {
    return (
      <Col lg="6" md="8">
        <h2>Duplicate a New Basket</h2>
        <Form onSubmit={handleDuplicateBasket}>
          <div>
            <Form.Label>Basket to Duplicate</Form.Label>
            <Form.Select
              value={selectedBasket}
              onChange={(event) => setSelectedBasket(event.target.value)}
            >
              <option value={''} data-storeid={''} data-retailer={'superstore'}>
                Choose a basket...
              </option>
              {user.baskets.map((b) => {
                return (
                  <option
                    value={b.id}
                    data-storeid={b.storeID}
                    data-retailer={b.retailer}
                    key={b.id}
                  >
                    {b.name}
                  </option>
                )
              })}
            </Form.Select>
          </div>
          <br />
          <div>
            <Form.Label>New Basket Name</Form.Label>
            <Form.Control
              type="text"
              value={basketName}
              name="basketName"
              id="basketName"
              onChange={({ target }) => setBasketName(target.value)}
            />
          </div>
          <br />
          <div>
            <Form.Label>New Basket Store</Form.Label>
            <Stack direction="horizontal" gap={2}>
              <StoreSelect
                store={basketStore}
                name="basketStore"
                id="basketStore"
                setStore={setBasketStore}
                retailer={basketRetailer}
                setRetailer={setBasketRetailer}
                retailerFilter={retailers}
              />
            </Stack>
          </div>
          <br />
          <div>
            <Button type="submit" id="create-button">
              Create Duplicate Basket
            </Button>
          </div>
          {loading && (
            <p>Working... Please wait, this could take a few minutes.</p>
          )}
        </Form>
      </Col>
    )
  }

  if (errorList.length === 0) {
    return (
      <p>
        Basket Successfully Duplicated. All items were added to the new basket!
      </p>
    )
  } else {
    return (
      <div>
        <p>
          Basket Successfully Duplicated. Could not add the following items to
          the new basket:
        </p>
        <Row xs={2} sm={2} md={3} lg={4} xl={5} className="g-2">
          {errorList.map((product) => {
            const regPrices = product.prices.filter(
              (p) => p.priceType === 'REGULAR'
            )
            const sale =
              product.prices[product.prices.length - 1].priceType !== 'REGULAR'
            const regPrice =
              regPrices.length && sale
                ? regPrices[regPrices.length - 1].price
                : null
            return (
              <ProductCard
                product={product}
                price={product.prices[product.prices.length - 1].price}
                regPrice={regPrice}
                sale={sale}
                key={product.code}
                image={product.image}
                basketPage={true}
              />
            )
          })}
        </Row>
      </div>
    )
  }
}

export default DuplicateBasket
