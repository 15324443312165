import { Link, useParams } from 'react-router-dom'
import { useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import basketService from '../services/basket'
import Row from 'react-bootstrap/Row'
import ProductCard from './ProductCard'
import { isCurrentPrice, toPriceString } from '../utils'

const Basket = () => {
  const defaultBasket = useSelector(({ basket }) => basket)
  const user = useSelector(({ user }) => user)
  const [basket, setBasket] = useState(null)
  const basketParam = useParams().basket
  const [saleExpanded, setSaleExpanded] = useState(true)
  const [basketExpanded, setBasketExpanded] = useState(true)

  useEffect(() => {
    const getBasket = async (id) => {
      const b = await basketService.getBasket(id)
      setBasket(b)
    }

    if (basketParam) {
      getBasket(basketParam)
    } else {
      setBasket(defaultBasket)
    }
  }, [basketParam, setBasket, defaultBasket])

  if (
    !user &&
    ![
      '6328b3d344832f8f088be5a1',
      '6357b42739196ce58a6d8171',
      '6357ba7439196ce58a6d824d',
      '6357be4039196ce58a6d833a',
      '6362f80822dfdaf72e944701',
      '635c29a1bbaa981b2318b2c7',
      '63ee87b33c2dcad9d7202dc7',
      '63b5f3b91d0f9a99fa2ff5c4', //vancouver
      '63b5f4691d0f9a99fa300881',
      '646c0341c571fb9bd8ce5133',
      '63b5f5fb1d0f9a99fa301b39',
      '646d9179c571fb9bd8d9b874',
      '63891f6343b7808306f526e7', //toronto
      '63891f9b43b7808306f526f1',
      '646c045dc571fb9bd8d004c5',
      '638925fe43b7808306f5da2a',
      '63891fdd43b7808306f526f6'
    ].includes(basketParam)
  ) {
    return (
      <div>
        Please <Link to="/login">login</Link>
      </div>
    )
  }
  if (!basket) {
    return <div>Loading...</div>
  }

  const saleItems = basket.products.filter(
    (p) => p.prices[p.prices.length - 1].priceType !== 'REGULAR'
  )

  const basketPrice = basket.products.reduce(
    (a, b) => a + b.prices[b.prices.length - 1].price,
    0
  )

  return (
    <div>
      <h2>Basket: {basket.name}</h2>
      <p>
        {basket.products.length}{' '}
        {basket.products.length === 1 ? 'item' : 'items'} with a total cost of{' '}
        {toPriceString(basketPrice)}
      </p>
      <h3>
        {saleExpanded ? (
          <button
            className="expandButton"
            onClick={() => setSaleExpanded(false)}
          >
            -
          </button>
        ) : (
          <button
            className="expandButton"
            onClick={() => setSaleExpanded(true)}
          >
            +
          </button>
        )}{' '}
        On Sale This Week
      </h3>
      {saleItems?.length && saleExpanded ? (
        <Row xs={2} sm={2} md={3} lg={4} xl={5} g={2} className="g-2">
          {saleItems.map((product) => {
            const regPrices = product.prices.filter(
              (p) => p.priceType === 'REGULAR'
            )
            const regPrice = regPrices.length
              ? regPrices[regPrices.length - 1].price
              : null
            if (isCurrentPrice(product.prices[product.prices.length - 1])) {
              return (
                <ProductCard
                  product={product}
                  regPrice={regPrice}
                  price={product.prices[product.prices.length - 1].price}
                  sale={true}
                  key={product.code}
                  image={product.image}
                  basketPage={true}
                />
              )
            }
            return <Fragment key={product.code}></Fragment>
          })}
        </Row>
      ) : !saleExpanded ? (
        ''
      ) : (
        <p>Sorry, nothing in this basket is currently on sale.</p>
      )}

      <h3>
        {basketExpanded ? (
          <button
            className="expandButton"
            onClick={() => setBasketExpanded(false)}
          >
            -
          </button>
        ) : (
          <button
            className="expandButton"
            onClick={() => setBasketExpanded(true)}
          >
            +
          </button>
        )}{' '}
        All Basket Items
      </h3>
      <Row xs={2} sm={2} md={3} lg={4} xl={5} className="g-2">
        {basketExpanded &&
          basket.products.map((product) => {
            const regPrices = product.prices.filter(
              (p) => p.priceType === 'REGULAR'
            )
            const sale =
              product.prices[product.prices.length - 1].priceType !== 'REGULAR'
            const regPrice =
              regPrices.length && sale
                ? regPrices[regPrices.length - 1].price
                : null
            return (
              <ProductCard
                product={product}
                price={product.prices[product.prices.length - 1].price}
                regPrice={regPrice}
                sale={sale}
                key={product.code}
                image={product.image}
                basketPage={true}
              />
            )
          })}
      </Row>
      <p style={{ marginTop: '1rem' }}>
        <strong>*</strong> denotes a product where we are having trouble
        retreiving current price data. Price displayed is the most recent
        available data and may not be accurate.
      </p>
    </div>
  )
}

export default Basket
