import { createSlice } from '@reduxjs/toolkit'

const storeSlice = createSlice({
  name: 'store',
  initialState: '',
  reducers: {
    setTheStore(state, action) {
      return action.payload
    }
  }
})

export const initializeStore = (store) => {
  return (dispatch, getState) => {
    dispatch(setTheStore(store))
  }
}

export const { setTheStore } = storeSlice.actions

export default storeSlice.reducer
