import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link45deg } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import { getRetailerName, retailerList } from '../utils'

const RetailerLink = ({ product }) => {
  const stores = useSelector(({ stores }) => stores)
  const [link, setLink] = useState('')
  const retailerName = getRetailerName(product)
  const store = product.storeID

  useEffect(() => {
    let newLink = retailerList[product.retailer]?.retailLink.replace(
      '%store%',
      store
    )
    if (newLink.includes('%shortstorename%')) {
      newLink = Object.keys(stores).length
        ? newLink.replace(
            '%shortstorename%',
            stores[product.retailer].find((s) => s.id === store).shortname
          )
        : newLink.replace('%shortstorename%', 'villagesquare')
    }
    setLink(newLink)
  }, [product.retailer, setLink, store, stores])

  return (
    <Card.Link
      href={`${link}${product.code}`}
      target="_blank"
      title={`Buy from ${retailerName}`}
    >
      <Link45deg />
    </Card.Link>
  )
}

export default RetailerLink
