import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toPriceString, retailerList, isCurrentPrice } from '../utils'
import { Row, Col } from 'react-bootstrap'
import { BagDashFill, BagPlus } from 'react-bootstrap-icons'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  scales,
  TimeScale,
  LineController
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { addToBasket, removeFromBasket } from '../reducers/basketReducer'
import { setError } from '../reducers/notificationReducer'
import PriceForm from './PriceForm'
import RetailerLink from './RetailerLink'
import productService from '../services/product'
import PriceCharts from './PriceCharts'
import LowPriceBadge from './LowPriceBadge'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  scales,
  TimeScale,
  LineController
)

const Product = ({ retailer }) => {
  const [product, setProduct] = useState()
  const [allProducts, setAllProducts] = useState()

  const code = useParams().code
  const store = useParams().store
  const user = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  const basket = useSelector(({ basket }) => basket)
  const basketStore = basket && store === basket.storeID //Are we searching the same store as the basket's store? False if there is no basket/user isn't logged in

  const addProduct = async (event, code) => {
    event.preventDefault()
    await dispatch(addToBasket(code))
  }

  const removeProduct = async (event, code) => {
    event.preventDefault()
    await dispatch(removeFromBasket(code))
  }

  useEffect(() => {
    const updateProduct = async (code) => {
      const retailerService = retailerList[retailer || 'superstore'].service
      const p = await retailerService.getProduct(code, store).catch((error) => {
        if (error.response) {
          dispatch(setError('Sorry, could not load this product'))
        }
      })
      setAllProducts(p)
      setProduct(p.filter((product) => product.storeID === store)[0])
    }
    if (code && store) {
      updateProduct(code)
    }
  }, [code, store, retailer, dispatch])

  if (!product) {
    return <div>Loading...</div>
  }

  const regPrices = product.prices.filter((p) => p.priceType === 'REGULAR')

  const regPrice = regPrices.length
    ? regPrices[regPrices.length - 1].price
    : null

  const currentPrice = product.prices[product.prices.length - 1]
  const price = currentPrice.price
  const isCurrent = isCurrentPrice(currentPrice) ? '' : ' old-price'

  const displayPrice =
    currentPrice.priceType !== 'REGULAR' ? (
      regPrice ? (
        <span className={`sale${isCurrent}`}>
          {toPriceString(price)} <s>({toPriceString(regPrice)})</s>
        </span>
      ) : (
        <span className={`sale${isCurrent}`}>{toPriceString(price)}</span>
      )
    ) : (
      <span className={`${isCurrent}`}>{toPriceString(price)}</span>
    )

  const unitPrice = productService.getUnitPrice(currentPrice)

  return (
    <div>
      <Link to={-1}>Go Back</Link>
      <Row>
        <Col lg={10} md={9} sm={8} xs={8}>
          <LowPriceBadge
            prices={product.prices}
            isCurrent={isCurrentPrice(currentPrice)}
          />
          <h2>{product.name}</h2>
          <h5>{product.brand}</h5>
          <p>
            {currentPrice.size
              ? /[a-z]/i.test(currentPrice.size)
                ? currentPrice.size
                : `${currentPrice.size} ${currentPrice.unit}`
              : null}
          </p>
          <p>
            Price: {displayPrice}
            {currentPrice.endDate ? (
              <span>
                {' - '}
                until {currentPrice.endDate.split('T')[0].replaceAll('-', '/')}
              </span>
            ) : null}
            <br />
            {unitPrice || ''}
          </p>
          {isCurrentPrice(currentPrice) ? (
            ''
          ) : (
            <p>
              * Unable to retreive current price. Showing the most recent
              recorded price. This usually means the product is out of stock.
            </p>
          )}
        </Col>
        <Col>
          <img
            src={product.image}
            alt={product.name}
            width="100%"
            className="productImage"
          />
          <div className="cardLinks">
            {basketStore ? (
              basket.products.some((p) => p.code === product.code) ? (
                <a
                  href="#removefrombasket"
                  onClick={(event) => removeProduct(event, product.code)}
                  title="Remove from Basket"
                >
                  <BagDashFill />
                </a>
              ) : (
                <a
                  href="#addtobasket"
                  onClick={(event) => addProduct(event, product.code)}
                  title="Add to Basket"
                >
                  <BagPlus />
                </a>
              )
            ) : (
              ''
            )}
            <RetailerLink product={product} />
          </div>
        </Col>
      </Row>
      <PriceCharts
        allProducts={allProducts}
        store={store}
        product={product}
        unitPrice={unitPrice}
      />
      {user && (user.username === 'dustin' || user.username === 'dtaylorl') ? (
        <PriceForm code={code} storeID={store} />
      ) : null}
    </div>
  )
}

export default Product
