import { createSlice } from '@reduxjs/toolkit'
import loginService from '../services/login'
import { setNotification, setError } from './notificationReducer'
import { clearBasket, initializeBasket } from './basketReducer'
import basketService from '../services/basket'

const initialState = null

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return action.payload
    },
    clearUser() {
      return initialState
    }
  }
})

export const { setUser, clearUser } = userSlice.actions

export const initializeUser = () => {
  return async (dispatch) => {
    const loggedInUserJSON = window.localStorage.getItem('loggedInUser')
    if (loggedInUserJSON) {
      const loggedInUser = JSON.parse(loggedInUserJSON)
      const baskets = await basketService.getUserBaskets(loggedInUser.id)
      dispatch(setUser({ ...loggedInUser, baskets: baskets }))
    }
  }
}

export const logoutUser = () => {
  return async (dispatch) => {
    window.localStorage.removeItem('loggedInUser')
    window.localStorage.removeItem('currentBasket')
    dispatch(clearUser())
    dispatch(clearBasket())
    dispatch(setNotification('user logged out'))
  }
}

export const loginUser = (username, password, setUsername, setPassword) => {
  return async (dispatch) => {
    try {
      const user = await loginService.login({ username, password })
      user.expiry = Date.now() + 7 * 1000 * 60 * 60 * 24 //session expires in 7 days
      setUsername('')
      setPassword('')
      window.localStorage.setItem('loggedInUser', JSON.stringify(user))
      dispatch(
        user.verified
          ? setNotification(`Welcome ${user.name}`)
          : setNotification(
              `Welcome ${user.name}, please click here to verify your email`,
              10,
              { link: '/verify' }
            )
      )
      // dispatch(setUser(user))
      dispatch(initializeUser())
      dispatch(initializeBasket(user.baskets[0].id))
    } catch (exception) {
      dispatch(setError('Wrong username or password'))
    }
  }
}

export const registerUser = (
  name,
  username,
  email,
  favouriteStore,
  favouriteRetailer,
  updates,
  password,
  setName,
  setUsername,
  setEmail,
  setPassword
) => {
  return async (dispatch) => {
    try {
      const user = await loginService.register({
        name,
        username,
        email,
        favouriteStore,
        favouriteRetailer,
        updates,
        password
      })
      setName('')
      setUsername('')
      setEmail('')
      setPassword('')
      window.localStorage.setItem('loggedInUser', JSON.stringify(user))
      dispatch(setNotification(`Welcome ${user.name}`))
      dispatch(setUser(user))
    } catch (exception) {
      dispatch(setError('Error: ' + exception.response.data.error))
    }
  }
}

export default userSlice.reducer
