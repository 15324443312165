import React from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setStores } from '../reducers/storesReducer'
import locationService from '../services/location'

const Location = () => {
  const stores = useSelector(({ stores }) => stores)
  const dispatch = useDispatch()

  const getGeolocation = (event) => {
    navigator.geolocation.getCurrentPosition((position) => {
      const sortedStores = locationService.sortAllByLocation(
        stores,
        position.coords.latitude,
        position.coords.longitude
      )
      dispatch(setStores(sortedStores))

      localStorage.setItem(
        'user-location',
        `${position.coords.latitude},${position.coords.longitude}`
      )
    })
  }

  return (
    <Button
      variant="secondary"
      type="button"
      onClick={getGeolocation}
      title="Sort Stores by Distance from Your Location"
    >
      <GeoAltFill size={19} className="iconButton" />
    </Button>
  )
}

export default Location
