import Form from 'react-bootstrap/Form'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { initializeBasket } from '../reducers/basketReducer'
import { useMatch, useNavigate } from 'react-router-dom'
import { retailerList } from '../utils'

const BasketSelect = () => {
  const user = useSelector(({ user }) => user)
  const basket = useSelector(({ basket }) => basket)
  const [selectedBasket, setSelectedBasket] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const termMatch = useMatch('/search/:store/:term')
  const termMatchAlt = useMatch('/search/:retailer/:store/:term')
  const storeMatch = useMatch('/search/:store/')

  const handleBasketChange = (event) => {
    if (event.target.value === 'newBasket') {
      navigate('/basket/new')
    } else if (event.target.value === 'manageBaskets') {
      navigate('/baskets')
    } else {
      dispatch(initializeBasket(event.target.value))
      setSelectedBasket(event.target.value)
      window.localStorage.setItem('currentBasket', event.target.value)

      //change the url/store if we're on a search page
      if (termMatch || termMatchAlt) {
        if (termMatch?.params && retailerList[termMatch.params.store]) {
          navigate(`/search/`)
        } else {
          navigate(
            `/search${
              retailerList[
                event.target.selectedOptions[0].getAttribute('data-retailer')
              ].trackerLink
            }${event.target.selectedOptions[0].getAttribute('data-storeid')}/${
              termMatch?.params?.term || termMatchAlt.params.term
            }`
          )
        }
      } else if (storeMatch) {
        navigate(`/search/`)
      }
    }
  }

  useEffect(() => {
    if (basket) {
      setSelectedBasket(basket.id)
    }
  }, [basket])

  if (!user || !user.baskets) {
    return <></>
  }

  return (
    <Form.Select
      className="basketSelect"
      size="sm"
      value={selectedBasket}
      onChange={handleBasketChange}
    >
      {user.baskets.map((b) => {
        return (
          <option
            value={b.id}
            data-storeid={b.storeID}
            data-retailer={b.retailer}
            key={b.id}
          >
            {b.name}
          </option>
        )
      })}
      <option value="newBasket" key="newBasket">
        Create a New Basket
      </option>
      <option value="manageBaskets" key="manageBaskets">
        Manage Baskets
      </option>
    </Form.Select>
  )
}

export default BasketSelect
