import axios from 'axios'
import { retailerList } from '../utils'

const getDistance = (lat1, lon1, lat2, lon2) => {
  const p = 0.0174532925 // pi / 180
  const c = Math.cos
  const a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(a))
}

const getLocation = (stores, location) => {
  axios
    .get(`https://geocoder.ca/?locate=${location}&json=1`)
    .then((response) => {
      const sortedStores = locationService.sortAllByLocation(
        stores,
        response.data.latt,
        response.data.longt
      )
      return sortedStores
    })
}

const sortByLocation = (stores, lat, lon) => {
  return stores.sort(
    (a, b) =>
      getDistance(lat, lon, a.latt, a.long) -
      getDistance(lat, lon, b.latt, b.long)
  )
}

const sortAllByLocation = (stores, lat, lon) => {
  const sortedStores = {}
  Object.keys(retailerList).forEach(
    (retailer) =>
      (sortedStores[retailer] = sortByLocation([...stores[retailer]], lat, lon))
  )
  return sortedStores
}

const getLocationByIP = async () => {
  const response = await axios.get('/api/location/byIP', { timeout: 2000 })
  console.log(response)
  let city
  switch (response.data.province) {
    case 'BC':
    case 'YT':
      city = 'Vancouver'
      break
    case 'AB':
    case 'SK':
    case 'MB':
    case 'NT':
      city = 'Calgary'
      break
    default:
      city = 'Toronto'
      break
  }
  response.data.city = city
  return response.data
}

const locationService = {
  getDistance,
  getLocation,
  sortByLocation,
  sortAllByLocation,
  getLocationByIP
}

export default locationService
