import { createSlice } from '@reduxjs/toolkit'

const storesSlice = createSlice({
  name: 'stores',
  initialState: {},
  reducers: {
    setStores(state, action) {
      return action.payload
    }
  }
})

export const initializeStores = (store) => {
  return (dispatch, getState) => {
    dispatch(setStores(store))
  }
}

export const { setStores } = storesSlice.actions

export default storesSlice.reducer
