import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setError, setNotification } from '../reducers/notificationReducer'
import loginService from '../services/login'

const UnsubscribeUser = () => {
  const token = useParams().token
  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = async () => {
      const request = await loginService.unsubscribeUser(token)
      if (request?.status === 200) {
        dispatch(
          setNotification(
            `You have been successfully unsubscribed from all emails`
          )
        )
      } else {
        dispatch(setError(request.data))
      }
    }

    if (token) {
      unsubscribe()
    }
  }, [dispatch, token])

  return <div></div>
}

export default UnsubscribeUser
