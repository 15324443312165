import axios from 'axios'
const baseUrl = '/api/baskets'

const getBasket = async (id) => {
  const req = await axios.get(`${baseUrl}/${id}`)
  return req.data
}

const create = async (name, user, store, retailer, updates, token) => {
  const config = {
    headers: { Authorization: `bearer ${token}` }
  }
  const response = await axios.post(
    baseUrl,
    {
      name: name,
      user: user,
      storeID: store,
      retailer: retailer,
      updates: updates || false
    },
    config
  )
  return response.data
}

const addProduct = async (id, product, store, retailer, token) => {
  const config = {
    headers: { Authorization: `bearer ${token}` }
  }
  const response = await axios.put(
    `${baseUrl}/${id}`,
    { product: product, store: store, retailer: retailer },
    config
  )
  return response.data
}

const removeProduct = async (id, product, store, retailer, token) => {
  const config = {
    data: { product: product, store: store, retailer: retailer },
    headers: { Authorization: `bearer ${token}` }
  }
  const response = await axios.delete(`${baseUrl}/${id}`, config)
  return response.data
}

const deleteBasket = async (id, token) => {
  const config = {
    data: { id: id },
    headers: { Authorization: `bearer ${token}` }
  }
  const response = await axios.delete(`${baseUrl}`, config)
  return response.data
}

const getUserBaskets = async (user) => {
  const response = await axios.get(`${baseUrl}/user/${user}`)
  return response.data
}

const changeEmailUpdates = async (id, emailUpdates, token) => {
  const config = {
    headers: { Authorization: `bearer ${token}` }
  }
  const response = await axios.put(
    `${baseUrl}/email/${id}`,
    { emailUpdates: emailUpdates },
    config
  )
  return response.data
}

const getTotalByDate = (products, date) => {
  let oldPrices = 0

  return [
    date.getTime(),
    products.map((product) => {
      const priceToUse = product.reduce(
        (a, b) =>
          date.getTime() - new Date(a.date).getTime() >
            date.getTime() - new Date(b.date).getTime() &&
          date.getTime() - new Date(b.date).getTime() >= 0
            ? b
            : a,
        { date: new Date('2000-01-01') }
      )

      if (
        Date.parse(new Date(priceToUse.date)) <
        Date.parse(
          new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
        )
      )
        oldPrices++
      return priceToUse
    }),
    oldPrices < products.length / 2
  ]
}

const duplicate = async (name, user, store, retailer, basketID, token) => {
  const config = {
    headers: { Authorization: `bearer ${token}` }
  }
  const response = await axios.post(
    `${baseUrl}/duplicate/${basketID}`,
    {
      name: name,
      user: user,
      store: store,
      retailer: retailer
    },
    config
  )
  return response.data
}

const basketService = {
  getBasket,
  create,
  addProduct,
  removeProduct,
  deleteBasket,
  getUserBaskets,
  changeEmailUpdates,
  getTotalByDate,
  duplicate
}

export default basketService
