import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Logout from './Logout'
import { Navbar, Nav, Button } from 'react-bootstrap'
import { BagCheckFill } from 'react-bootstrap-icons'
import BasketSelect from './BasketSelect'

const Navigation = () => {
  const user = useSelector(({ user }) => user)

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <div className="container">
        <Link to="/" className="iconLink" title="Grocery Tracker">
          <BagCheckFill color="#dc3545" size={22} />
        </Link>
        <h1>
          <Link to="/">Grocery Tracker</Link>
        </h1>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#" as="span">
              <Link to="/search">Search</Link>
            </Nav.Link>
            {user ? (
              <Nav.Link href="#" as="span">
                <Link to="/basket">Current Basket</Link>
              </Nav.Link>
            ) : (
              ''
            )}
          </Nav>
          <BasketSelect />
          {user ? (
            <Nav className="mr-auto">
              <Nav.Link href="#" as="span">
                <Link to="/baskets">All Baskets</Link>
              </Nav.Link>
            </Nav>
          ) : (
            ''
          )}
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {user ? (
              <>
                <span className="navbar-text">Logged in as {user.name}</span>
                <Logout />
              </>
            ) : (
              <Button
                style={{ margin: '0 0.5rem' }}
                variant="primary"
                as={Link}
                to="/login"
              >
                Login/Sign Up
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navigation
