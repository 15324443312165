import { useState } from 'react'
import { Form, Button, Stack } from 'react-bootstrap'
import StoreSelect from './StoreSelect'
import Col from 'react-bootstrap/Col'
import { useDispatch } from 'react-redux'
import { createBasket } from '../reducers/basketReducer'
import { useNavigate } from 'react-router-dom'

const NewBasket = () => {
  const [basketName, setBasketName] = useState('')
  const [basketStore, setBasketStore] = useState('')
  const [basketRetailer, setBasketRetailer] = useState('superstore')
  const [updates, setUpdates] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCreateBasket = async (event) => {
    event.preventDefault()
    dispatch(createBasket(basketName, basketStore, basketRetailer, updates))
    navigate('/basket')
  }

  return (
    <Col lg="6" md="8">
      <h2>Create a New Basket</h2>
      <Form onSubmit={handleCreateBasket}>
        <div>
          <Form.Label>Basket Name</Form.Label>
          <Form.Control
            type="text"
            value={basketName}
            name="basketName"
            id="basketName"
            onChange={({ target }) => setBasketName(target.value)}
          />
        </div>
        <br />
        <div>
          <Form.Label>Basket Store</Form.Label>
          <Stack direction="horizontal" gap={2}>
            <StoreSelect
              store={basketStore}
              name="basketStore"
              id="basketStore"
              setStore={setBasketStore}
              retailer={basketRetailer}
              setRetailer={setBasketRetailer}
            />
          </Stack>
        </div>
        <br />
        <div>
          <Form.Check
            checked={updates}
            name="updates"
            id="updates"
            onChange={({ target }) => setUpdates(target.checked)}
            label="Receive email updates for this basket?"
          />
          <br />
          <Button type="submit" id="create-button">
            Create Basket
          </Button>
        </div>
      </Form>
    </Col>
  )
}

export default NewBasket
